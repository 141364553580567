<template>
  <div>
    <v-divider />

    <AppSettingsLatestRelease />

    <AppSettingsDocumentationBuild />

    <AppSettingsLatestCommit />
  </div>
</template>
