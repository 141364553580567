
<script setup lang="ts">
const meta = {"nav":"Installation","title":"Get started with Vuetify 3","description":"Details for v3 release - faq, changes, and upgrading.","keywords":"migration, releases, upgrading vuetify, beta, v3"}
 const assets = undefined
 const backmatter = undefined
 const features = undefined
 const fluid = undefined
 const related = ["/getting-started/contributing/","/introduction/roadmap/","/getting-started/release-notes/"]
 const toc = [{"text":"Installation","to":"#installation","level":2},{"text":"Using Vite","to":"#using-vite","level":2},{"text":"Using Nuxt 3","to":"#using-nuxt-3","level":2},{"text":"Manual setup","to":"#manual-setup","level":3},{"text":"vuetify-nuxt-module","to":"#vuetify-nuxt-module","level":3},{"text":"Using Laravel Mix","to":"#using-laravel-mix","level":2},{"text":"Using CDN","to":"#using-cdn","level":2},{"text":"Using Vitepress","to":"#using-vitepress","level":2},{"text":"Existing projects","to":"#existing-projects","level":2},{"text":"SSR caveats","to":"#ssr-caveats","level":2},{"text":"Exposed exports","to":"#exposed-exports","level":2},{"text":"JS / TS","to":"#js-ts","level":3},{"text":"SASS","to":"#sass","level":3},{"text":"Nightly Builds","to":"#nightly-builds","level":2},{"text":"Questions","to":"#questions","level":2}]
  defineExpose({ frontmatter: {"meta":{"nav":"Installation","title":"Get started with Vuetify 3","description":"Details for v3 release - faq, changes, and upgrading.","keywords":"migration, releases, upgrading vuetify, beta, v3"},"related":["/getting-started/contributing/","/introduction/roadmap/","/getting-started/release-notes/"],"toc":[{"text":"Installation","to":"#installation","level":2},{"text":"Using Vite","to":"#using-vite","level":2},{"text":"Using Nuxt 3","to":"#using-nuxt-3","level":2},{"text":"Manual setup","to":"#manual-setup","level":3},{"text":"vuetify-nuxt-module","to":"#vuetify-nuxt-module","level":3},{"text":"Using Laravel Mix","to":"#using-laravel-mix","level":2},{"text":"Using CDN","to":"#using-cdn","level":2},{"text":"Using Vitepress","to":"#using-vitepress","level":2},{"text":"Existing projects","to":"#existing-projects","level":2},{"text":"SSR caveats","to":"#ssr-caveats","level":2},{"text":"Exposed exports","to":"#exposed-exports","level":2},{"text":"JS / TS","to":"#js-ts","level":3},{"text":"SASS","to":"#sass","level":3},{"text":"Nightly Builds","to":"#nightly-builds","level":2},{"text":"Questions","to":"#questions","level":2}]}, excerpt: undefined })
  
  import { version } from 'vuetify'

</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {"meta":{"nav":"Installation","title":"Get started with Vuetify 3","description":"Details for v3 release - faq, changes, and upgrading.","keywords":"migration, releases, upgrading vuetify, beta, v3"},"related":["/getting-started/contributing/","/introduction/roadmap/","/getting-started/release-notes/"],"toc":[{"text":"Installation","to":"#installation","level":2},{"text":"Using Vite","to":"#using-vite","level":2},{"text":"Using Nuxt 3","to":"#using-nuxt-3","level":2},{"text":"Manual setup","to":"#manual-setup","level":3},{"text":"vuetify-nuxt-module","to":"#vuetify-nuxt-module","level":3},{"text":"Using Laravel Mix","to":"#using-laravel-mix","level":2},{"text":"Using CDN","to":"#using-cdn","level":2},{"text":"Using Vitepress","to":"#using-vitepress","level":2},{"text":"Existing projects","to":"#existing-projects","level":2},{"text":"SSR caveats","to":"#ssr-caveats","level":2},{"text":"Exposed exports","to":"#exposed-exports","level":2},{"text":"JS / TS","to":"#js-ts","level":3},{"text":"SASS","to":"#sass","level":3},{"text":"Nightly Builds","to":"#nightly-builds","level":2},{"text":"Questions","to":"#questions","level":2}]}
export const excerpt: string | undefined = ""

</script>
<template>
<div>
<section id="get-started-with-vuetify-3">
<app-heading href="#get-started-with-vuetify-3" level="1">Get started with Vuetify 3</app-heading>
<p>Get started with Vuetify, the world’s most popular Vue.js framework for building feature rich, blazing fast applications.</p>
<page-features></page-features>
<vo-promotions-card-highlight slug="vuemastery-getting-started"></vo-promotions-card-highlight>
<section id="installation">
<app-heading href="#installation" level="2">Installation</app-heading>
<p>Vuetify has support for multiple different installation paths with the most common scaffolding tool being <app-link href="https://github.com/vuetifyjs/create-vuetify">create-vuetify</app-link></p>
<p>For more information regarding supported package managers, please visit their official websites:</p>
<ul>
<li><app-link href="https://pnpm.io/">pnpm</app-link></li>
<li><app-link href="https://yarnpkg.com/">yarn</app-link></li>
<li><app-link href="https://npmjs.org/">npm</app-link></li>
<li><app-link href="https://bun.sh/package-manager">bun</app-link></li>
</ul>
</section>
<section id="using-vite">
<app-heading href="#using-vite" level="2">Using Vite</app-heading>
<p>To get started with Vuetify 3, simply paste the following code into your terminal:</p>
<doc-tabs>
<template #tabs="">
<v-tab value="pnpm" variant="plain" class="text-none">pnpm</v-tab>
<v-tab value="yarn" variant="plain" class="text-none">yarn</v-tab>
<v-tab value="npm" variant="plain" class="text-none">npm</v-tab>
<v-tab value="bun" variant="plain" class="text-none">bun</v-tab>
</template>
<template #content="">
<v-window-item value="pnpm">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash"><span class="token function">pnpm</span> create vuetify
</code></pre>
</app-markup>
</v-window-item>
<v-window-item value="yarn">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash"><span class="token function">yarn</span> create vuetify
</code></pre>
</app-markup>
</v-window-item>
<v-window-item value="npm">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash"><span class="token function">npm</span> create vuetify@latest
</code></pre>
</app-markup>
</v-window-item>
<v-window-item value="bun">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash">bun create vuetify
</code></pre>
</app-markup>
</v-window-item>
</template>
</doc-tabs>
<p>This command prompts you with a few options before generating your scaffolded Vue / Vuetify 3 project.</p>
<app-markup resource="" class="mb-4"><pre class="language-bash" v-pre="true"><code class="language-bash">success Installed <span class="token string">"create-vuetify@x.x.x"</span> with binaries:
    - create-vuetify

? Project name: ❯ vuetify-project // the folder to generate your application
? Use TypeScript?: ❯ No / Yes
? Would you like to <span class="token function">install</span> dependencies with yarn, npm, or pnpm?:
  ❯ <span class="token function">yarn</span>
    <span class="token function">npm</span>
    <span class="token function">pnpm</span>
    bun
    none
</code></pre>
</app-markup><p>After making your selections, <app-link href="https://github.com/vuetifyjs/create-vuetify">create-vuetify</app-link> will generate the structure for your new application.</p>
<p>Once the scaffold is complete, start the vite development server by running the following commands:</p>
<app-markup resource="" class="mb-4"><pre class="language-bash" v-pre="true"><code class="language-bash"><span class="token builtin class-name">cd</span> vuetify-project
<span class="token function">pnpm</span> dev
</code></pre>
</app-markup><vo-promotions-card-vuetify slug="vuetify-one"></vo-promotions-card-vuetify>
</section>
<section id="using-nuxt-3">
<app-heading href="#using-nuxt-3" level="2">Using Nuxt 3</app-heading>
<p><app-link href="https://nuxt.com/">Nuxt</app-link> is an open-source framework that has helpful features to quickly get you started with developing a full-stack Vue app, such as file-based routing, SSR and component auto-imports.</p>
<section id="manual-setup">
<app-heading href="#manual-setup" level="3">Manual setup</app-heading>
<p>Nuxt is powered by Nitro and can be used with Vite or Webpack 5 bundlers, so the steps to get Vuetify working in Nuxt 3 are quite similar to <app-link href="#existing-projects">the manual steps described below</app-link>.</p>
<p>Start off creating a nuxt app by executing the following commands:</p>
<doc-tabs>
<template #tabs="">
<v-tab value="pnpm" variant="plain" class="text-none">pnpm</v-tab>
<v-tab value="yarn" variant="plain" class="text-none">yarn</v-tab>
<v-tab value="npm" variant="plain" class="text-none">npm</v-tab>
<v-tab value="bun" variant="plain" class="text-none">bun</v-tab>
</template>
<template #content="">
<v-window-item value="pnpm">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash">pnpx nuxi@latest init <span class="token operator">&lt;</span>project-name<span class="token operator">></span>
<span class="token builtin class-name">cd</span> <span class="token operator">&lt;</span>project-name<span class="token operator">></span>
<span class="token comment"># Create a .npmrc file with shamefully-hoist=true</span>
<span class="token function">pnpm</span> <span class="token function">install</span>
</code></pre>
</app-markup>
</v-window-item>
<v-window-item value="yarn">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash">npx nuxi@latest init <span class="token operator">&lt;</span>project-name<span class="token operator">></span>
<span class="token builtin class-name">cd</span> <span class="token operator">&lt;</span>project-name<span class="token operator">></span>
<span class="token function">yarn</span>
</code></pre>
</app-markup>
</v-window-item>
<v-window-item value="npm">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash">npx nuxi@latest init <span class="token operator">&lt;</span>project-name<span class="token operator">></span>
<span class="token builtin class-name">cd</span> <span class="token operator">&lt;</span>project-name<span class="token operator">></span>
<span class="token function">npm</span> <span class="token function">install</span>
</code></pre>
</app-markup>
</v-window-item>
<v-window-item value="bun">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash">bunx nuxi@latest init <span class="token operator">&lt;</span>project-name<span class="token operator">></span>
<span class="token builtin class-name">cd</span> <span class="token operator">&lt;</span>project-name<span class="token operator">></span>
bun <span class="token function">install</span>
</code></pre>
</app-markup>
</v-window-item>
</template>
</doc-tabs>
<p>and then install the required Vuetify modules as dependencies:</p>
<doc-tabs>
<template #tabs="">
<v-tab value="pnpm" variant="plain" class="text-none">pnpm</v-tab>
<v-tab value="yarn" variant="plain" class="text-none">yarn</v-tab>
<v-tab value="npm" variant="plain" class="text-none">npm</v-tab>
<v-tab value="bun" variant="plain" class="text-none">bun</v-tab>
</template>
<template #content="">
<v-window-item value="pnpm">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash"><span class="token function">pnpm</span> i <span class="token parameter variable">-D</span> vuetify vite-plugin-vuetify
<span class="token function">pnpm</span> i @mdi/font
</code></pre>
</app-markup>
</v-window-item>
<v-window-item value="yarn">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash"><span class="token function">yarn</span> <span class="token function">add</span> <span class="token parameter variable">-D</span> vuetify vite-plugin-vuetify
<span class="token function">yarn</span> <span class="token function">add</span> @mdi/font
</code></pre>
</app-markup>
</v-window-item>
<v-window-item value="npm">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash"><span class="token function">npm</span> i <span class="token parameter variable">-D</span> vuetify vite-plugin-vuetify
<span class="token function">npm</span> i @mdi/font
</code></pre>
</app-markup>
</v-window-item>
<v-window-item value="bun">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash">bun <span class="token function">add</span> <span class="token parameter variable">-d</span> vuetify vite-plugin-vuetify
bun <span class="token function">add</span> @mdi/font
</code></pre>
</app-markup>
</v-window-item>
</template>
</doc-tabs>
<p>Next, integrate the following entries into your <v-code>nuxt.config.ts</v-code> file:</p>
<app-markup resource="nuxt.config.ts" class="mb-4"><pre class="language-ts" v-pre="true"><code data-resource="nuxt.config.ts" class="language-ts"><span class="token keyword">import</span> vuetify<span class="token punctuation">,</span> <span class="token punctuation">{</span> transformAssetUrls <span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">'vite-plugin-vuetify'</span>
<span class="token keyword">export</span> <span class="token keyword">default</span> <span class="token function">defineNuxtConfig</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
  <span class="token comment">//...</span>
  build<span class="token operator">:</span> <span class="token punctuation">{</span>
    transpile<span class="token operator">:</span> <span class="token punctuation">[</span><span class="token string">'vuetify'</span><span class="token punctuation">]</span><span class="token punctuation">,</span>
  <span class="token punctuation">}</span><span class="token punctuation">,</span>
  modules<span class="token operator">:</span> <span class="token punctuation">[</span>
    <span class="token punctuation">(</span>_options<span class="token punctuation">,</span> nuxt<span class="token punctuation">)</span> <span class="token operator">=></span> <span class="token punctuation">{</span>
      nuxt<span class="token punctuation">.</span>hooks<span class="token punctuation">.</span><span class="token function">hook</span><span class="token punctuation">(</span><span class="token string">'vite:extendConfig'</span><span class="token punctuation">,</span> <span class="token punctuation">(</span>config<span class="token punctuation">)</span> <span class="token operator">=></span> <span class="token punctuation">{</span>
        <span class="token comment">// @ts-expect-error</span>
        config<span class="token punctuation">.</span>plugins<span class="token punctuation">.</span><span class="token function">push</span><span class="token punctuation">(</span><span class="token function">vuetify</span><span class="token punctuation">(</span><span class="token punctuation">{</span> autoImport<span class="token operator">:</span> <span class="token boolean">true</span> <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">)</span>
      <span class="token punctuation">}</span><span class="token punctuation">)</span>
    <span class="token punctuation">}</span><span class="token punctuation">,</span>
    <span class="token comment">//...</span>
  <span class="token punctuation">]</span><span class="token punctuation">,</span>
  vite<span class="token operator">:</span> <span class="token punctuation">{</span>
    vue<span class="token operator">:</span> <span class="token punctuation">{</span>
      template<span class="token operator">:</span> <span class="token punctuation">{</span>
        transformAssetUrls<span class="token punctuation">,</span>
      <span class="token punctuation">}</span><span class="token punctuation">,</span>
    <span class="token punctuation">}</span><span class="token punctuation">,</span>
  <span class="token punctuation">}</span><span class="token punctuation">,</span>
<span class="token punctuation">}</span><span class="token punctuation">)</span>
</code></pre>
</app-markup><p>Nuxt allows you to change its Vite config by using its built-in hook <v-code>vite:extendConfig</v-code>. In its callback function, add the Vuetify plugin to the array of Vite plugins. To resolve relative asset URLs that are passed to Vuetify components such as <v-code>VImg</v-code> (e.g. <v-code>~/assets/img/some.png</v-code>) the <v-code>transformAssetUrls</v-code> function needs to be added in the <v-code>vite</v-code> entry .</p>
<p>In the next step, initialize Vuetify and add it to the main Vue app instance. This can be done in the <v-code>plugins</v-code> folder as any plugin that is placed in this folder will be automatically loaded by Nuxt at startup.</p>
<app-markup resource="~/plugins/vuetify.ts" class="mb-4"><pre class="language-ts" v-pre="true"><code data-resource="~/plugins/vuetify.ts" class="language-ts"><span class="token comment">// import this after install `@mdi/font` package</span>
<span class="token keyword">import</span> <span class="token string">'@mdi/font/css/materialdesignicons.css'</span>

<span class="token keyword">import</span> <span class="token string">'vuetify/styles'</span>
<span class="token keyword">import</span> <span class="token punctuation">{</span> createVuetify <span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">'vuetify'</span>

<span class="token keyword">export</span> <span class="token keyword">default</span> <span class="token function">defineNuxtPlugin</span><span class="token punctuation">(</span><span class="token punctuation">(</span>app<span class="token punctuation">)</span> <span class="token operator">=></span> <span class="token punctuation">{</span>
  <span class="token keyword">const</span> vuetify <span class="token operator">=</span> <span class="token function">createVuetify</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
    <span class="token comment">// ... your configuration</span>
  <span class="token punctuation">}</span><span class="token punctuation">)</span>
  app<span class="token punctuation">.</span>vueApp<span class="token punctuation">.</span><span class="token function">use</span><span class="token punctuation">(</span>vuetify<span class="token punctuation">)</span>
<span class="token punctuation">}</span><span class="token punctuation">)</span>
</code></pre>
</app-markup><p>Finally, add Vuetify’s root <v-code>VApp</v-code> component either in <v-code>~/app.vue</v-code> or <v-code>~/layouts/default.vue</v-code>, for example:</p>
<app-markup resource="app.vue" class="mb-4"><pre class="language-html" v-pre="true"><code data-resource="app.vue" class="language-html"><span class="token tag"><span class="token tag"><span class="token punctuation">&lt;</span>template</span><span class="token punctuation">></span></span>
  <span class="token tag"><span class="token tag"><span class="token punctuation">&lt;</span>NuxtLayout</span><span class="token punctuation">></span></span>
    <span class="token tag"><span class="token tag"><span class="token punctuation">&lt;</span>v-app</span><span class="token punctuation">></span></span>
      <span class="token tag"><span class="token tag"><span class="token punctuation">&lt;</span>NuxtPage</span> <span class="token punctuation">/></span></span>
    <span class="token tag"><span class="token tag"><span class="token punctuation">&lt;/</span>v-app</span><span class="token punctuation">></span></span>
  <span class="token tag"><span class="token tag"><span class="token punctuation">&lt;/</span>NuxtLayout</span><span class="token punctuation">></span></span>
<span class="token tag"><span class="token tag"><span class="token punctuation">&lt;/</span>template</span><span class="token punctuation">></span></span>
</code></pre>
</app-markup><p>or</p>
<app-markup resource="~/layouts/default.vue" class="mb-4"><pre class="language-html" v-pre="true"><code data-resource="~/layouts/default.vue" class="language-html"><span class="token tag"><span class="token tag"><span class="token punctuation">&lt;</span>template</span><span class="token punctuation">></span></span>
  <span class="token tag"><span class="token tag"><span class="token punctuation">&lt;</span>v-app</span><span class="token punctuation">></span></span>
    <span class="token comment">&lt;!-- .... --></span>
  <span class="token tag"><span class="token tag"><span class="token punctuation">&lt;/</span>v-app</span><span class="token punctuation">></span></span>
<span class="token tag"><span class="token tag"><span class="token punctuation">&lt;/</span>template</span><span class="token punctuation">></span></span>
</code></pre>
</app-markup><p>You should now have access to all Vuetify components and tools in the Nuxt app.</p>
</section>
<section id="vuetify-nuxt-module">
<app-heading href="#vuetify-nuxt-module" level="3">vuetify-nuxt-module</app-heading>
<p>Alternatively, you can use the <app-link href="https://github.com/vuetifyjs/nuxt-module">vuetify-nuxt-module</app-link> (works only with Vite). The module is strongly opinionated and has a built-in default configuration out of the box. You can use it without any configuration, and it will work for most use cases.</p>
<p>Check the <app-link href="https://nuxt.vuetifyjs.com/">documentation</app-link> for more information on how to use it.</p>
</section>
</section>
<section id="using-laravel-mix">
<app-heading href="#using-laravel-mix" level="2">Using Laravel Mix</app-heading>
<app-markup resource="" class="mb-4"><pre class="language-js" v-pre="true"><code class="language-js"><span class="token keyword">import</span> <span class="token punctuation">{</span> createApp <span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">'vue'</span>

<span class="token comment">// Vuetify</span>
<span class="token keyword">import</span> <span class="token string">'@mdi/font/css/materialdesignicons.css'</span>
<span class="token keyword">import</span> <span class="token string">'vuetify/styles'</span>
<span class="token keyword">import</span> <span class="token punctuation">{</span> createVuetify <span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">'vuetify'</span>
<span class="token keyword">import</span> <span class="token operator">*</span> <span class="token keyword">as</span> components <span class="token keyword">from</span> <span class="token string">'vuetify/components'</span>
<span class="token keyword">import</span> <span class="token operator">*</span> <span class="token keyword">as</span> directives <span class="token keyword">from</span> <span class="token string">'vuetify/directives'</span>

<span class="token comment">// Components</span>
<span class="token keyword">import</span> App <span class="token keyword">from</span> <span class="token string">'./App.vue'</span>

<span class="token keyword">const</span> vuetify <span class="token operator">=</span> <span class="token function">createVuetify</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
  components<span class="token punctuation">,</span>
  directives
<span class="token punctuation">}</span><span class="token punctuation">)</span>

<span class="token function">createApp</span><span class="token punctuation">(</span>App<span class="token punctuation">)</span><span class="token punctuation">.</span><span class="token function">use</span><span class="token punctuation">(</span>vuetify<span class="token punctuation">)</span><span class="token punctuation">.</span><span class="token function">mount</span><span class="token punctuation">(</span><span class="token string">'#app'</span><span class="token punctuation">)</span>
</code></pre>
</app-markup><p>To import the fonts you need to add to webpack.mix.js:</p>
<app-markup resource="" class="mb-4"><pre class="language-js" v-pre="true"><code class="language-js">mix<span class="token punctuation">.</span><span class="token function">copy</span><span class="token punctuation">(</span><span class="token string">'node_modules/@mdi/font/fonts/'</span><span class="token punctuation">,</span> <span class="token string">'dist/fonts/'</span><span class="token punctuation">)</span>
</code></pre>
</app-markup></section>
<section id="using-cdn">
<app-heading href="#using-cdn" level="2">Using CDN</app-heading>
<p>We recommend using the latest version of Vuetify 3 from <app-link href="https://www.jsdelivr.com/">jsdelivr</app-link>. All components and styles are included.</p>
<p class="text-truncate"><v-code>https://cdn.jsdelivr.net/npm/vuetify@{{ version }}/dist/vuetify.min.css</v-code></p>
<p class="text-truncate"><v-code>https://cdn.jsdelivr.net/npm/vuetify@{{ version }}/dist/vuetify.min.js</v-code></p>
<app-markup resource="" class="mb-4"><pre class="language-js" v-pre="true"><code class="language-js"><span class="token keyword">const</span> <span class="token punctuation">{</span> createApp <span class="token punctuation">}</span> <span class="token operator">=</span> Vue
<span class="token keyword">const</span> <span class="token punctuation">{</span> createVuetify <span class="token punctuation">}</span> <span class="token operator">=</span> Vuetify

<span class="token keyword">const</span> vuetify <span class="token operator">=</span> <span class="token function">createVuetify</span><span class="token punctuation">(</span><span class="token punctuation">)</span>

<span class="token keyword">const</span> app <span class="token operator">=</span> <span class="token function">createApp</span><span class="token punctuation">(</span><span class="token punctuation">)</span>
app<span class="token punctuation">.</span><span class="token function">use</span><span class="token punctuation">(</span>vuetify<span class="token punctuation">)</span><span class="token punctuation">.</span><span class="token function">mount</span><span class="token punctuation">(</span><span class="token string">'#app'</span><span class="token punctuation">)</span>
</code></pre>
</app-markup></section>
<section id="using-vitepress">
<app-heading href="#using-vitepress" level="2">Using Vitepress</app-heading>
<p>You can use Vuetify’s components in your Vitepress static site.</p>
<p>After initializing your Vitepress project, add Vuetify to your dependencies</p>
<doc-tabs>
<template #tabs="">
<v-tab value="pnpm" variant="plain" class="text-none">pnpm</v-tab>
<v-tab value="yarn" variant="plain" class="text-none">yarn</v-tab>
<v-tab value="npm" variant="plain" class="text-none">npm</v-tab>
<v-tab value="bun" variant="plain" class="text-none">bun</v-tab>
</template>
<template #content="">
<v-window-item value="pnpm">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash"><span class="token function">pnpm</span> i vuetify
</code></pre>
</app-markup>
</v-window-item>
<v-window-item value="yarn">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash"><span class="token function">yarn</span> <span class="token function">add</span> vuetify
</code></pre>
</app-markup>
</v-window-item>
<v-window-item value="npm">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash"><span class="token function">npm</span> i vuetify
</code></pre>
</app-markup>
</v-window-item>
<v-window-item value="bun">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash">bun <span class="token function">add</span> vuetify
</code></pre>
</app-markup>
</v-window-item>
</template>
</doc-tabs>
<p>Then, in your <v-code>.vitepress/theme/index.ts</v-code></p>
<app-markup resource="" class="mb-4"><pre class="language-ts" v-pre="true"><code class="language-ts"><span class="token keyword">import</span> DefaultTheme <span class="token keyword">from</span> <span class="token string">'vitepress/theme'</span>
<span class="token keyword">import</span> <span class="token string">'vuetify/styles'</span>
<span class="token keyword">import</span> <span class="token operator">*</span> <span class="token keyword">as</span> components <span class="token keyword">from</span> <span class="token string">'vuetify/components'</span>
<span class="token keyword">import</span> <span class="token operator">*</span> <span class="token keyword">as</span> directives <span class="token keyword">from</span> <span class="token string">'vuetify/directives'</span>
<span class="token keyword">import</span> <span class="token punctuation">{</span> createVuetify <span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">'vuetify'</span>

<span class="token keyword">const</span> vuetify <span class="token operator">=</span> <span class="token function">createVuetify</span><span class="token punctuation">(</span><span class="token punctuation">{</span> components<span class="token punctuation">,</span> directives <span class="token punctuation">}</span><span class="token punctuation">)</span>

<span class="token keyword">export</span> <span class="token keyword">default</span> <span class="token punctuation">{</span>
  <span class="token operator">...</span>DefaultTheme<span class="token punctuation">,</span>
  <span class="token function">enhanceApp</span><span class="token punctuation">(</span><span class="token punctuation">{</span> app <span class="token punctuation">}</span><span class="token punctuation">)</span> <span class="token punctuation">{</span>
    app<span class="token punctuation">.</span><span class="token function">use</span><span class="token punctuation">(</span>vuetify<span class="token punctuation">)</span>
  <span class="token punctuation">}</span><span class="token punctuation">,</span>
<span class="token punctuation">}</span>
</code></pre>
</app-markup></section>
<section id="existing-projects">
<app-heading href="#existing-projects" level="2">Existing projects</app-heading>
<p>Follow these steps if for example you are adding Vuetify to an existing project, or simply do not want to use a scaffolding tool.</p>
<doc-tabs>
<template #tabs="">
<v-tab value="pnpm" variant="plain" class="text-none">pnpm</v-tab>
<v-tab value="yarn" variant="plain" class="text-none">yarn</v-tab>
<v-tab value="npm" variant="plain" class="text-none">npm</v-tab>
<v-tab value="bun" variant="plain" class="text-none">bun</v-tab>
</template>
<template #content="">
<v-window-item value="pnpm">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash"><span class="token function">pnpm</span> i vuetify
</code></pre>
</app-markup>
</v-window-item>
<v-window-item value="yarn">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash"><span class="token function">yarn</span> <span class="token function">add</span> vuetify
</code></pre>
</app-markup>
</v-window-item>
<v-window-item value="npm">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash"><span class="token function">npm</span> i vuetify
</code></pre>
</app-markup>
</v-window-item>
<v-window-item value="bun">
<app-markup resource="" class="mb-4"><pre class="language-bash"><code class="language-bash">bun <span class="token function">add</span> vuetify
</code></pre>
</app-markup>
</v-window-item>
</template>
</doc-tabs>
<alert type="tip">
<p><strong>TIP:</strong></p>
<p>If you are upgrading from an earlier version of Vuetify, make sure to check out our <app-link href="/getting-started/upgrade-guide/">Upgrade Guide</app-link></p>
</alert>
<p>In the file where you create the Vue application, add the following code</p>
<app-markup resource="" class="mb-4"><pre class="language-js" v-pre="true"><code class="language-js"><span class="token keyword">import</span> <span class="token punctuation">{</span> createApp <span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">'vue'</span>

<span class="token comment">// Vuetify</span>
<span class="token keyword">import</span> <span class="token string">'vuetify/styles'</span>
<span class="token keyword">import</span> <span class="token punctuation">{</span> createVuetify <span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">'vuetify'</span>
<span class="token keyword">import</span> <span class="token operator">*</span> <span class="token keyword">as</span> components <span class="token keyword">from</span> <span class="token string">'vuetify/components'</span>
<span class="token keyword">import</span> <span class="token operator">*</span> <span class="token keyword">as</span> directives <span class="token keyword">from</span> <span class="token string">'vuetify/directives'</span>

<span class="token comment">// Components</span>
<span class="token keyword">import</span> App <span class="token keyword">from</span> <span class="token string">'./App.vue'</span>

<span class="token keyword">const</span> vuetify <span class="token operator">=</span> <span class="token function">createVuetify</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
  components<span class="token punctuation">,</span>
  directives<span class="token punctuation">,</span>
<span class="token punctuation">}</span><span class="token punctuation">)</span>

<span class="token function">createApp</span><span class="token punctuation">(</span>App<span class="token punctuation">)</span><span class="token punctuation">.</span><span class="token function">use</span><span class="token punctuation">(</span>vuetify<span class="token punctuation">)</span><span class="token punctuation">.</span><span class="token function">mount</span><span class="token punctuation">(</span><span class="token string">'#app'</span><span class="token punctuation">)</span>
</code></pre>
</app-markup><p>This will include all components and directives regardless of whether or not you are using them. If you instead only want to include used components, have a look at the <app-link href="https://npmjs.com/package/vite-plugin-vuetify">Vite</app-link> or <app-link href="https://npmjs.com/package/webpack-plugin-vuetify">Webpack</app-link> plugins, depending on your setup. The plugins also makes it possible to customize SCSS variables.</p>
<p>Lastly, do not forget to install <app-link href="/features/icon-fonts/">icons</app-link>.</p>
</section>
<section id="ssr-caveats">
<app-heading href="#ssr-caveats" level="2">SSR caveats</app-heading>
<p>Vue 3 has no way to automatically detect if SSR is used — so nuxt, gridsome, and other SSR frameworks must manually set the <v-code>ssr</v-code> option to <v-code>true</v-code> in order to properly render the application.</p>
<app-markup resource="src/plugins/vuetify.js" class="mb-4"><pre class="language-js" v-pre="true"><code data-resource="src/plugins/vuetify.js" class="language-js"><span class="token keyword">import</span> <span class="token string">'@mdi/font/css/materialdesignicons.css'</span>
<span class="token keyword">import</span> <span class="token string">'vuetify/styles'</span>

<span class="token keyword">const</span> vuetify <span class="token operator">=</span> <span class="token function">createVuetify</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
  <span class="token literal-property property">ssr</span><span class="token operator">:</span> <span class="token boolean">true</span><span class="token punctuation">,</span>
<span class="token punctuation">}</span><span class="token punctuation">)</span>
</code></pre>
</app-markup></section>
<section id="exposed-exports">
<app-heading href="#exposed-exports" level="2">Exposed exports</app-heading>
<p>The following export paths exist for the Vuetify framework:</p>
<section id="js-ts">
<app-heading href="#js-ts" level="3">JS / TS</app-heading>
<app-table>
<thead>
<tr>
<th>Name</th>
<th>Description</th>
</tr>
</thead>
<tbody>
<tr>
<td><v-code>vuetify</v-code></td>
<td>Main entry point. Contains <v-code>createVuetify()</v-code> and public composables.</td>
</tr>
<tr>
<td><v-code>vuetify/styles</v-code></td>
<td>Precompiled global CSS (reset, utilities, etc.), no component styles. Will be redirected to SASS if <v-code>styles.configFile</v-code> is set in vite or webpack.</td>
</tr>
<tr>
<td><v-code>vuetify/components</v-code></td>
<td>All components. Not recommended as it will include all components during development, slowing down your build.</td>
</tr>
<tr>
<td><v-code>vuetify/components/&lt;name&gt;</v-code></td>
<td>Individual components. Grouped by top-level name, for example VListItem, VListGroup, and VListItemTitle are all in <v-code>vuetify/components/VList</v-code>.</td>
</tr>
<tr>
<td><v-code>vuetify/directives</v-code></td>
<td>All directives.</td>
</tr>
<tr>
<td><v-code>vuetify/directives/&lt;name&gt;</v-code></td>
<td>Individual directives.</td>
</tr>
<tr>
<td><v-code>vuetify/blueprints/&lt;name&gt;</v-code></td>
<td>Preset collections of prop defaults.</td>
</tr>
<tr>
<td><v-code>vuetify/locale</v-code></td>
<td>Translations for strings in vuetify components. Each language is a named export.</td>
</tr>
<tr>
<td><v-code>vuetify/locale/adapters/&lt;name&gt;</v-code></td>
<td>Adapters to retrieve translations from other libraries such as vue-i18n.</td>
</tr>
<tr>
<td><v-code>vuetify/iconsets/&lt;name&gt;</v-code></td>
<td>Icon presets, see <app-link href="/features/icon-fonts/">Icon Fonts</app-link></td>
</tr>
</tbody>
</app-table>
</section>
<section id="sass">
<app-heading href="#sass" level="3">SASS</app-heading>
<p>See <app-link href="/features/sass-variables/">SASS Variables</app-link> for more information.</p>
<app-table>
<thead>
<tr>
<th>Name</th>
<th>Description</th>
</tr>
</thead>
<tbody>
<tr>
<td><v-code>vuetify</v-code></td>
<td>Global CSS (reset, utilities, etc.), no component styles. Equivalent to <v-code>vuetify/styles</v-code> in JS.</td>
</tr>
<tr>
<td><v-code>vuetify/settings</v-code></td>
<td>All SASS variables, including component variables.</td>
</tr>
<tr>
<td><v-code>vuetify/tools</v-code></td>
<td>Mixins and functions.</td>
</tr>
</tbody>
</app-table>
</section>
</section>
<section id="nightly-builds">
<app-heading href="#nightly-builds" level="2">Nightly Builds</app-heading>
<p>The three development branches (<v-code>master</v-code>, <v-code>dev</v-code>, and <v-code>next</v-code>) are automatically published to NPM at 1200 UTC under the <app-link href="https://www.npmjs.com/package/@vuetify/nightly?activeTab=versions"><v-code>@vuetify/nightly</v-code></app-link> namespace. They may be outdated or buggy and are therefore not officially supported and are only supplied for testing purposes. These builds can be installed with a <app-link href="https://docs.npmjs.com/cli/v8/commands/npm-install#:~:text=Install%20a%20package%20under%20a%20custom%20alias">package alias</app-link>.</p>
<app-table>
<thead>
<tr>
<th>Branch name</th>
<th>Purpose</th>
<th>package.json entry</th>
<th>Changelog</th>
</tr>
</thead>
<tbody>
<tr>
<td><v-code>master</v-code></td>
<td>Bug fixes</td>
<td><v-code>&quot;vuetify&quot;: &quot;npm:@vuetify/nightly@latest&quot;</v-code></td>
<td><app-link href="https://unpkg.com/@vuetify/nightly@latest/CHANGELOG.md">Changelog</app-link></td>
</tr>
<tr>
<td><v-code>dev</v-code></td>
<td>New features</td>
<td><v-code>&quot;vuetify&quot;: &quot;npm:@vuetify/nightly@dev&quot;</v-code></td>
<td><app-link href="https://unpkg.com/@vuetify/nightly@dev/CHANGELOG.md">Changelog</app-link></td>
</tr>
<tr>
<td><v-code>next</v-code></td>
<td>Breaking changes</td>
<td><v-code>&quot;vuetify&quot;: &quot;npm:@vuetify/nightly@next&quot;</v-code></td>
<td><app-link href="https://unpkg.com/@vuetify/nightly@next/CHANGELOG.md">Changelog</app-link></td>
</tr>
</tbody>
</app-table>
<app-markup resource="" class="mb-4"><pre class="language-diff" v-pre="true"><code class="language-diff"><span class="token unchanged"><span class="token prefix unchanged"> </span><span class="token line">"devDependencies": {
</span></span><span class="token deleted-sign deleted"><span class="token prefix deleted">-</span><span class="token line">  "vuetify": "^3.3.0"
</span></span><span class="token inserted-sign inserted"><span class="token prefix inserted">+</span><span class="token line">  "vuetify": "npm:@vuetify/nightly@3.3.0-master.2023-05-21"
</span></span><span class="token unchanged"><span class="token prefix unchanged"> </span><span class="token line">}
</span></span></code></pre>
</app-markup></section>
<section id="questions">
<app-heading href="#questions" level="2">Questions</app-heading>
<p>Have a question that belongs here? Tell us in our <app-link href="https://community.vuetifyjs.com/">Discord Community</app-link> or create a request on our <app-link href="https://issues.vuetifyjs.com/">Issue Generator</app-link>.</p>
<promoted-promoted slug="vuetify-discord"></promoted-promoted>
</section>
</section>
</div>
</template>
